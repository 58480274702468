#productshop {
  .heading{
    border-bottom: 5px #ff8910 solid;
    display: inline-block;
  }
  img{
    max-height: 150px;
  }
  .support{
    .card{
      max-width: 100%;

    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Werbeagentur Christian Aichner
 */
