.product-item {
  .def-number-input {
    margin: auto;
    width: 150px !important;
  }
  .number-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .number-input input[type="number"]::-webkit-inner-spin-button,
  .number-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .number-input {
    margin-bottom: 3rem;
  }
  .number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
  }
  .number-input button:before,
  .number-input button:after {
    display: inline-block;
    position: absolute;
    content: "";
    height: 2px;
    transform: translate(-50%, -50%);
  }
  .number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .number-input input[type="number"] {
    text-align: center;
  }
  .number-input.number-input {
    border: 1px solid #ced4da;
    width: 10rem;
    border-radius: 0.25rem;
  }
  .number-input.number-input button {
    width: 2.6rem;
    height: 0.7rem;
  }
  .number-input.number-input button.minus {
    padding-left: 10px;
  }
  .number-input.number-input button:before,
  .number-input.number-input button:after {
    width: 0.7rem;
    background-color: #495057;
  }
  .number-input.number-input input[type="number"] {
    max-width: 4rem;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-width: 0 1px;
    font-size: 1rem;
    height: 2rem;
    color: #495057;
  }

  img {
    max-height: 215px;
  }

  .badge{
    i{
      margin-right: .4rem;
    }
    padding: .4rem;
    margin: .2rem;
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Werbeagentur Christian Aichner
 */
