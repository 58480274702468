#shop {
  background: darken(#fff,3%);
  .card {
    z-index: 2;
    .card-body{
      padding: 0;
    }
    /* in stock */
    .card-title{
      background-color: #2e2e2e;
      padding: 1.5rem;
      color: white;
      font-weight: bolder;
      font-size: 1.7rem;
      margin-bottom: 0;
    }
    .card-footer {
      background-color: darken(#fff,4%);
    }
    /* out of stock */
    &.outofstock{
      .card-title{
        background-color: lighten(#ff8910,20%);
        padding: 1.5rem;
        color: white;
        font-weight: bolder;
        font-size: 1.7rem;
        margin-bottom: 0;
      }
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Werbeagentur Christian Aichner
 */
