.page-footer {
  li {
    color: white;
		background: rgba(255, 255, 255, 0.1);
		transition: all 0.1s ease;
		padding: 1rem !important;
		align-items: center;
		margin-bottom: .4rem;
		margin-bottom: .4rem;
		&:hover {
			background: rgba(255, 255, 255, 0.2);
		}
		.badge {
			box-shadow: none;
		}
	}
	img{
		max-height: 40px;
	}
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Werbeagentur Christian Aichner
 */
